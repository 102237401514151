<template>
    <div class="container">
        <div class="hd_header">
            <div class="g_w1200 g_div">
                <img src="./../../assets/img/logo.png" class="g_logo">
                <span class="g_span">创项课服务平台</span>
            </div>
        </div>
        <div class="login_container">
            <div class="g_w1200">
                <div class="login_img">
                    <img src="https://register.ccopyright.com.cn/assets/img/user/loginMain-4f97a3d0.png" alt="">
                </div>
                <div class="login_div">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <div class="hd_footer">
            <div class="hd_f_width">
                <a hideFocus="true" class="foot_a" href="">关于我们</a>
                <span class="sepline">|</span>
                <a hideFocus="true" class="foot_a" href="">帮助中心</a>
                <span class="sepline">|</span>
                <a hideFocus="true" class="foot_a" href="">联系我们</a>
                <span class="sepline">|</span>
                <a hideFocus="true" class="foot_a" href="">常见问题</a>
                <span class="sepline">|</span>
                <a hideFocus="true" class="foot_a" href="">在线客服</a>
                <p class="gray">
                    <span>Copyright © 创项课服务平台 2021 课之一（西安）科技有限公司 版权所有</span>
                </p>
                <p class="gray">
                    <img src="http://wsbs.shxga.gov.cn/webjjcluster/images/jinghui.ico" alt="" style="width: 19px; margin-top: -4px; vertical-align: middle;"> 
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002001772" target="blank">
                        <span style="margin-right: 15px;">陕公网安备61019002001772号</span>
                    </a>
                    <a href="https://beian.miit.gov.cn/" target="blank">陕ICP备2021008899号-1</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../assets/css/login/login1.css';
</style>